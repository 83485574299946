* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --color-bg-dark: #141414;
  --color-title: #fafafa80;
  --inline-gaps: 1.15rem;
  --padding: .7rem;
  --spacing: 1rem;
  --font-size: 16px;
  --font-size-sml: 14px;
  --text-color: #cecdc3;
  --line-height: 1.425;
  --color-title-dark: #fafafa80;
  --color-title-light: #000;
  --color-hover-dark: #fafafa;
  --color-hover-light: #000;
}

@media only screen and (width >= 1140px) {
  :root {
    --font-size: 20px;
    --line-height: 1.45;
  }
}

[data-theme="dark"], [data-theme="light"] {
  color: #f1f1f1;
  background-color: #151515;
}

[data-theme="dark"] html, [data-theme="dark"] body, [data-theme="light"] html, [data-theme="light"] body {
  font-size: var(--font-size);
  line-height: var(--line-height);
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
  -webkit-font-feature-settings: "kern" 1, "dlig" 1, "ss01" 1;
  font-feature-settings: "kern" 1, "dlig" 1, "ss01" 1;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-decoration: none;
}

[data-theme="dark"] button, [data-theme="light"] button, [data-theme="dark"] nav, [data-theme="light"] nav {
  color: #f1f1f1;
}

[data-theme="dark"] a, [data-theme="light"] a {
  color: inherit;
}

[data-theme="dark"] a:hover, [data-theme="light"] a:hover {
  color: #a0a0a0;
}

[data-theme="dark"] p, [data-theme="light"] p {
  color: #f1f1f1;
}

[data-theme="dark"] .contacts a, [data-theme="light"] .contacts a {
  color: #f1f1f1;
  border-bottom: 1.4px solid #f1f1f1;
  transition: border-bottom .5s;
}

[data-theme="dark"] .contacts a:hover, [data-theme="light"] .contacts a:hover {
  border-bottom: 1.4px solid #fafafa;
}

[data-theme="dark"] .note-link, [data-theme="light"] .note-link {
  font-size: var(--font-size);
  color: #f1f1f1;
  text-decoration: none;
}

[data-theme="dark"] .note-link:hover, [data-theme="light"] .note-link:hover {
  color: #a0a0a0;
  cursor: pointer;
}

@media only screen and (width >= 1140px) {
  [data-theme="dark"] .note-link, [data-theme="light"] .note-link {
    font-size: var(--font-size);
  }
}

[data-theme="dark"] .note-tag, [data-theme="light"] .note-tag {
  color: gray;
}

[data-theme="dark"] .note-tag:hover, [data-theme="light"] .note-tag:hover {
  color: #f1f1f1;
}

[data-theme="light"] {
  color: #100f0f;
  background-color: #fffcf0;
}

[data-theme="light"] html, [data-theme="light"] body {
  background-color: #fffcf0;
}

[data-theme="light"] button, [data-theme="light"] nav, [data-theme="light"] a {
  color: #100f0f;
}

[data-theme="light"] a:hover {
  color: #a0a0a0;
}

[data-theme="light"] p {
  color: #100f0f;
}

[data-theme="light"] .contacts a {
  color: #100f0f;
  border-bottom: 1.4px solid #100f0f;
  transition: border-bottom .5s;
}

[data-theme="light"] .contacts a:hover {
  border-bottom: 1.4px solid var(--color-hover-light);
}

[data-theme="light"] .note-link {
  color: #100f0f;
  text-decoration: none;
}

[data-theme="light"] .note-link:hover {
  color: #888;
  cursor: pointer;
}

[data-theme="light"] .note-tag {
  color: gray;
}

[data-theme="light"] .note-tag:hover {
  color: #100f0f;
}

.serif, h2 {
  font-family: Literata Variable, PT Serif, Georgia, Times New Roman, Times, serif;
}

.system, body {
  font-family: -apple-system, BlinkMacSystemFont, Inter, IBM Plex Sans, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

blockquote.serif {
  font-family: Georgia, serif;
}

pre {
  font-size: calc(var(--font-size) / 1.5);
  white-space: pre-wrap;
  tab-size: 2;
  overflow-x: auto;
}

pre code {
  text-indent: -2ch;
  padding-left: 2ch;
  display: inline-block;
}

body {
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
  -webkit-font-feature-settings: "kern" 1, "dlig" 1, "ss01" 1;
  font-feature-settings: "kern" 1, "dlig" 1, "ss01" 1;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: var(--font-size);
  line-height: var(--line-height);
  letter-spacing: .01rem;
  color: #fff;
  background: #000;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (width >= 1140px) {
  body {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
}

@media only screen and (width >= 1400px) {
  body {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
}

@media only screen and (width >= 1800px) {
  body {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
}

a {
  text-decoration: none;
}

p {
  color: var(--text-color);
  line-height: var(--line-height);
  margin-bottom: calc(var(--spacing) * 1.5);
}

p.no-margin {
  margin-bottom: calc(var(--spacing) / 2);
}

ul {
  margin: 0;
  padding: 0;
}

ul.spacious-bullet li {
  margin-top: .75rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul li {
  padding-left: 1.2em;
  position: relative;
}

ul li:before {
  content: "✧";
  color: inherit;
  position: absolute;
  left: 0;
}

.sml-txt, .footer {
  font-size: .5rem;
}

h2 {
  font-size: calc(var(--font-size) * 1.2);
  margin-top: calc(var(--spacing) * 3.5);
  margin-bottom: calc(var(--spacing) * .75);
  text-decoration: none;
}

.hide-mobile {
  display: none;
}

@media only screen and (width >= 1140px) {
  .hide-mobile {
    display: block;
  }
}

.main-wrapper {
  padding: calc(var(--padding) * 1.4);
  z-index: 10;
}

@media only screen and (width >= 1140px) {
  .main-wrapper {
    padding: calc(var(--padding) * 1.333);
  }
}

.note-wrapper {
  overflow: none;
  grid-area: 1 / 1;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  height: 100%;
  display: grid;
}

.note-wrapper .notebook-container {
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.2rem;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (width >= 1140px) {
  .note-wrapper .notebook-container {
    margin-bottom: 1rem;
  }
}

.theme-btn {
  justify-content: flex-start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

button {
  font-size: var(--font-size);
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

button:hover {
  cursor: pointer;
  text-decoration: underline;
}

nav {
  padding: calc(var(--padding) * 1.333);
  color: #000;
}

.notebook-nav {
  z-index: 10;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.notebook-nav .notebook-nav-left {
  gap: var(--spacing);
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.notebook-nav .notebook-nav-right {
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing);
  text-align: right;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.notebook-icon {
  font-size: 16px;
}

.contacts {
  align-self: end;
  gap: var(--inline-gaps);
  grid-area: 2 / 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.footer {
  display: none;
}

@media only screen and (width >= 1140px) {
  .footer {
    color: #ffffff80;
    bottom: calc(var(--padding) * 1.15);
    right: calc(var(--padding) * 1.15);
    z-index: 99999999;
    display: block;
    position: absolute;
  }
}

.notes-wrapper {
  margin: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 12) 0;
}

.note-container {
  margin-top: calc(var(--spacing) * 2);
  -ms-flex-direction: column;
  flex-direction: column;
  gap: .15rem;
  display: -ms-flexbox;
  display: flex;
}

.note-container-meta {
  gap: calc(var(--spacing) * .5);
  font-size: var(--font-size-sml);
  color: gray;
  text-align: left;
  margin-top: .2rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.note-header {
  margin-bottom: calc(var(--spacing-xlrg) * 2);
}

.note-tags {
  margin-top: var(--spacing-xlrg);
}

.note-body {
  width: 100%;
}

@media only screen and (width >= 768px) {
  .note-body {
    width: 34em;
  }
}

@media only screen and (width >= 1140px) {
  .note-body {
    width: 32em;
  }
}

@media only screen and (width >= 1400px) {
  .note-body {
    width: 32em;
  }
}

@media only screen and (width >= 1800px) {
  .note-body {
    width: 32em;
  }
}

.note-body img {
  cursor: pointer;
  max-width: 100%;
  transition: transform .3s;
}

.note-body .image-container {
  display: inline-block;
  position: relative;
}

.note-body .image-container:hover .enlarge-text {
  opacity: 1;
}

.note-body .image-container.enlarged img {
  max-width: calc(100vw - var(--spacing) * 2);
}

@media only screen and (width >= 1140px) {
  .note-body .image-container.enlarged img {
    max-width: 66vw;
  }
}

.note-body .image-container .enlarge-text {
  color: #fff;
  padding: calc(var(--spacing) / 2);
  opacity: 0;
  pointer-events: none;
  background: #000000b3;
  border-radius: 5px;
  font-size: .75rem;
  transition: opacity .3s;
  position: absolute;
  top: 10px;
  right: 10px;
}

.note-wrapper {
  margin-top: calc(var(--spacing) * 3);
}

.accordion {
  background-color: #0000000d;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: .85rem;
}

.accordion-content {
  background-color: #fff;
  padding: 10px;
}

@media only screen and (width >= 1140px) {
  .accordion-content {
    padding: 15px;
  }
}

.accordion[data-theme="dark"] .accordion-header {
  color: #fff;
  background: #333;
}

.accordion[data-theme="dark"] .accordion-header:hover {
  background: #444;
}

.accordion[data-theme="dark"] .accordion-content {
  color: #fff;
  background: #222;
}

.accordion h3 {
  margin: 0;
  line-height: 1.5;
}

.accordion span {
  font-size: 1.2rem;
}

.accordion-header {
  cursor: pointer;
  color: #000;
  background: #f7f7f7;
  justify-content: space-between;
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
}

.accordion-header:hover {
  background: #eaeaea;
}

@media only screen and (width >= 1140px) {
  .accordion-header {
    padding: 15px;
  }
}

[data-theme="dark"] .accordion {
  background-color: #ffffff0d;
  border: 1px solid #444;
}

[data-theme="dark"] .accordion-header {
  color: #fff;
  background: #222;
}

[data-theme="dark"] .accordion-header:hover {
  background: #444;
}

[data-theme="dark"] .accordion-content {
  color: #fff;
  background: #222;
}

[data-theme="dark"] .accordion h3 {
  margin: 0;
}

[data-theme="dark"] .accordion span {
  font-size: 1.2rem;
}

.separator {
  background-color: #ccc;
  border: none;
  height: 1px;
  margin: 3rem 0;
}

.separator.thick {
  background-color: #888;
  height: 3px;
}

.styled-text {
  border-left: 3px solid #ccc;
  margin: 20px 0;
  padding-left: 10px;
}

.styled-quote {
  font-family: Source Serif Pro, Apple Garamond, Baskerville, Libre Baskerville, Droid Serif, Times New Roman, Times, serif, Noto Emoji, Quivira;
  font-size: calc(var(--font-size) * 1.15);
  color: #fff;
  border-left: 2px solid #ffffff80;
  margin: 20px 0;
  padding-left: 15px;
  line-height: 1.3;
}

.tags-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 768px) {
  .tags-list {
    width: 50%;
  }
}

.tags-list li {
  margin: 0;
}

.tags-list .tag-link {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 4px;
  padding: 5px 10px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.tags-list .tag-link:hover {
  background-color: var(--bg-tertiary);
}
/*# sourceMappingURL=index.d7ae6c7e.css.map */
