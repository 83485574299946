* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// *********************************** //
//            MEDIAQUERIES             //
// *********************************** //

$small: "only screen and (min-width: 480px)";
$medium: "only screen and (min-width: 768px)";
$large: "only screen and (min-width: 1140px)";
$xlarge: "only screen and (min-width: 1400px)";
$xxlarge: "only screen and (min-width: 1800px)";

@mixin phone {
  @media #{$small} {
    @content;
  }
}

@mixin tablet {
  @media #{$medium} {
    @content;
  }
}

@mixin desktop {
  @media #{$large} {
    @content;
  }
}

@mixin xldesktop {
  @media #{$xlarge} {
    @content;
  }
}

@mixin xxldesktop {
  @media #{$xxlarge} {
    @content;
  }
}

@mixin goodtype {
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
  -webkit-font-feature-settings:
    "kern" 1,
    "dlig" 1,
    "ss01" 1;
  font-feature-settings:
    "kern" 1,
    "dlig" 1,
    "ss01" 1;
  text-size-adjust: 100%;
  text-decoration: none;
}

// *********************************** //
//                ROOT                //
// *********************************** //

:root {
  // --color-bg-dark: rgba(20, 20, 20, 1);
  --color-bg-dark: #141414;
  --color-title: hsla(0, 0%, 98%, 0.5);
  --inline-gaps: 1.15rem;
  --padding: 0.7rem;
  --spacing: 1rem;
  --font-size: 16px;
  --font-size-sml: 14px;
  --text-color: rgb(206, 205, 195);
  --line-height: 1.425;
  @include desktop {
    --font-size: 20px;
    --line-height: 1.45;
  }
  // @include xldesktop {
  //   --font-size: 20px;
  //   --line-height: 1.45;
  // }
  // @include xxldesktop {
  //   --font-size: 20px;
  //   --line-height: 1.5;
  // }

  // buttons
  --color-title-dark: hsla(0, 0%, 98%, 0.5);
  --color-title-light: #000000;
  --color-hover-dark: hsla(0, 0%, 98%, 1);
  --color-hover-light: #000000;
}

// *********************************** //
//               THEMES                //
// *********************************** //

// Function to get theme properties
@function theme($theme-map, $property) {
  @return map-get($theme-map, $property);
}

// Theme Variables
$light-theme: (
  background-color: #fffcf0,
  link-color: rgb(36, 131, 123),
  text-color: rgb(16, 15, 15),
  text-color-hover: rgb(136, 136, 136),
);

$dark-theme: (
  // background-color: #100f0f,
  // text-color: rgb(206, 205, 195)
  background-color: rgb(21, 21, 21),
  link-color: rgb(36, 131, 123),
  text-color: rgb(241, 241, 241),
  text-color-hover: rgb(160, 160, 160)
);

// *********************************** //
//               DARK                 //
// *********************************** //
[data-theme="dark"],
[data-theme="light"] {
  color: theme($dark-theme, text-color);
  background-color: theme($dark-theme, background-color);

  html,
  body {
    font-size: var(--font-size);
    line-height: var(--line-height);
    @include goodtype;
  }

  button {
    color: theme($dark-theme, text-color);
  }

  nav {
    color: theme($dark-theme, text-color);
  }

  a {
    color: inherit;
    &:hover {
      color: theme($dark-theme, text-color-hover);
    }
  }
  p {
    color: theme($dark-theme, text-color);
  }

  .contacts {
    a {
      color: theme($dark-theme, text-color);
      border-bottom: 1.4px solid theme($dark-theme, text-color);
      transition: border-bottom 0.5s;
      &:hover {
        border-bottom: 1.4px solid hsla(0, 0%, 98%, 1);
      }
    }
  }

  .note-link {
    font-size: var(--font-size);
    color: theme($dark-theme, text-color);
    text-decoration: none;
    &:hover {
      color: theme($dark-theme, text-color-hover);
      cursor: pointer;
    }
    @include desktop {
      font-size: var(--font-size);
    }
  }

  .note-tag {
    color: gray;
    &:hover {
      color: theme($dark-theme, text-color);
    }
  }
}

// *********************************** //
//               LIGHT                 //
// *********************************** //
[data-theme="light"] {
  color: theme($light-theme, text-color);
  background-color: theme($light-theme, background-color);

  html,
  body {
    background-color: theme($light-theme, background-color);
  }

  button {
    color: theme($light-theme, text-color);
  }

  nav {
    color: theme($light-theme, text-color);
  }

  a {
    color: theme($light-theme, text-color);
    &:hover {
      color: theme($dark-theme, text-color-hover);
    }
  }

  p {
    color: theme($light-theme, text-color);
  }

  .contacts {
    a {
      color: theme($light-theme, text-color);
      border-bottom: 1.4px solid theme($light-theme, text-color);
      transition: border-bottom 0.5s;

      &:hover {
        border-bottom: 1.4px solid var(--color-hover-light);
      }
    }
  }

  .note-link {
    color: theme($light-theme, text-color);
    text-decoration: none;
    &:hover {
      color: theme($light-theme, text-color-hover);
      cursor: pointer;
    }
  }

  .note-tag {
    color: gray;
    &:hover {
      color: theme($light-theme, text-color);
    }
  }
}

// *********************************** //
//               FONTS                 //
// *********************************** //

.serif {
  font-family: "Source Serif Pro", "Apple Garamond", "Baskerville",
    "Libre Baskerville", "Droid Serif", "Times New Roman", "Times", serif,
    "Noto Emoji", "Quivira", serif;
  font-family: "Tiro Bangla", "Palatino Linotype", "Book Antiqua", "New York",
    "DejaVu serif", serif;
  font-family: "Literata Variable", "PT Serif", Georgia, "Times New Roman",
    Times, serif;
}

.system {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Inter",
    "IBM Plex Sans",
    Segoe UI,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

// *********************************** //
//              SANITY                 //
// *********************************** //
// quote blocks
blockquote.serif {
  font-family: "Georgia", serif;
}
// code blocks
pre {
  font-size: calc(var(--font-size) / 1.5);
  white-space: pre-wrap;
  // word-break: break-all;
  overflow-x: auto;
  tab-size: 2;
}
pre code {
  display: inline-block;
  padding-left: 2ch; /* Indentation amount */
  text-indent: -2ch; /* Negative indentation to align first line */
}

// *********************************** //
//                BASE                 //
// *********************************** //

body {
  @include goodtype;
  @extend .system;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size);
  line-height: var(--line-height);
  letter-spacing: 0.01rem;
  background: black;
  color: white;
  @include desktop {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
  @include xldesktop {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
  @include xxldesktop {
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
}

a {
  text-decoration: none;
}

p {
  color: var(--text-color);
  line-height: var(--line-height);
  margin-bottom: calc(var(--spacing) * 1.5);

  &.no-margin {
    margin-bottom: calc(var(--spacing) / 2);
  }
}

ul {
  margin: 0;
  padding: 0;
  &.spacious-bullet {
    li {
      margin-top: 0.75rem;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  // margin-left: 1rem;
  list-style-type: none;
}

ul li {
  position: relative;
  padding-left: 1.2em;
}

ul li::before {
  content: "✧";
  position: absolute;
  left: 0;
  color: inherit;
}

.sml-txt {
  font-size: 0.5rem;
}

h2 {
  font-size: calc(var(--font-size) * 1.2);
  margin-top: calc(var(--spacing) * 3.5);
  margin-bottom: calc(var(--spacing) * 0.75);
  // padding-bottom: calc(var(--spacing) * 0.15);
  text-decoration: none;
  @extend .serif;
  // border-bottom: 2px dotted white;
}

.hide-mobile {
  display: none;
  @include desktop {
    display: block;
  }
}

// *********************************** //
//              TOP-LEVEL              //
// *********************************** //

.main-wrapper {
  padding: calc(var(--padding) * 1.4);
  z-index: 10;
  @include desktop {
    padding: calc(var(--padding) * 1.333);
  }
}

.note-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  height: 100%;
  overflow: none;
  grid-column: 1;
  grid-row: 1;

  .notebook-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.2rem;

    @include desktop {
      margin-bottom: 1rem;
    }
  }
}

// *********************************** //
//                 BTNs                //
// *********************************** //
.theme-btn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  font-size: var(--font-size);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// *********************************** //
//                 NAV                 //
// *********************************** //
nav {
  padding: calc(var(--padding) * 1.333);
  color: black;
}

.notebook-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // padding: calc(var(--padding) * 1.4);
  z-index: 10;
  @include desktop {
    // padding: calc(var(--padding) * 1.333);
  }
  .notebook-nav-left {
    display: inline-flex;
    gap: var(--spacing);
  }
  .notebook-nav-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing);
    text-align: right;
  }
}

.notebook-icon {
  font-size: 16px;
}

.contacts {
  grid-column: 1;
  grid-row: 2;
  align-self: end;
  display: inline-flex;
  gap: var(--inline-gaps);
}

.footer {
  @extend .sml-txt;
  display: none;
  @include desktop {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: calc(var(--padding) * 1.15);
    right: calc(var(--padding) * 1.15);
    z-index: 99999999;
  }
}

// *********************************** //
//            NOTE ENTRY              //
// *********************************** //

.notes-wrapper {
  margin: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 12) 0;
}

.note-container {
  display: flex;
  flex-direction: column;
  margin-top: calc(var(--spacing) * 2);
  gap: 0.15rem;
}

.note-container-meta {
  margin-top: 0.2rem;
  display: inline-flex;
  gap: calc(var(--spacing) * 0.5);
  font-size: var(--font-size-sml);
  color: gray;
  text-align: left;
}

.note-header {
  margin-bottom: calc(var(--spacing-xlrg) * 2);
}

.note-tags {
  margin-top: var(--spacing-xlrg);
}

.note-body {
  width: 100%;
  @include tablet {
    width: 34em;
  }
  @include desktop {
    // width: 50%;
    width: 32em;
  }
  @include xldesktop {
    // width: 45%;
    width: 32em;
  }
  @include xxldesktop {
    // width: 34%;
    width: 32em;
  }
}

.note-body {
  img {
    max-width: 100%;
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .image-container {
    position: relative;
    display: inline-block;

    &:hover .enlarge-text {
      opacity: 1;
    }

    &.enlarged img {
      max-width: calc((100vw - var(--spacing) * 2));
      @include desktop {
        max-width: 66vw;
      }
    }

    .enlarge-text {
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      padding: calc(var(--spacing) / 2);
      border-radius: 5px;
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
      font-size: 0.75rem;
    }
  }
}

.note-wrapper {
  margin-top: calc(var(--spacing) * 3);
}

// *********************************** //
//             ACCORDION               //
// *********************************** //
.accordion {
  font-size: 0.85rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.05);

  &-content {
    padding: 10px;
    background-color: white;

    @include desktop {
      padding: 15px;
    }
  }

  &[data-theme="dark"] &-header {
    background: #333;
    color: white;

    &:hover {
      background: #444;
    }
  }

  &[data-theme="dark"] &-content {
    background: #222;
    color: white;
  }

  h3 {
    margin: 0;
    line-height: 1.5;
  }

  span {
    font-size: 1.2rem;
  }
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background: #f7f7f7;
  color: black;
  // font-weight: bold;

  &:hover {
    background: #eaeaea;
  }

  @include desktop {
    padding: 15px;
  }
}

[data-theme="dark"] .accordion {
  border: 1px solid #444;
  background-color: rgba(255, 255, 255, 0.05);

  &-header {
    background: #222;
    color: white;

    &:hover {
      background: #444;
    }
  }

  &-content {
    background: #222;
    color: white;
  }

  h3 {
    margin: 0;
  }

  span {
    font-size: 1.2rem;
  }
}

// *********************************** //
//             SEPARATOR               //
// *********************************** //
.separator {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 3rem 0;

  &.thick {
    height: 3px;
    background-color: #888;
  }
}

// *********************************** //
//               QUOTE                 //
// *********************************** //
// General style for all text with style
.styled-text {
  border-left: 3px solid #ccc;
  padding-left: 10px;
  margin: 20px 0;
}

// *********************************** //
//               TAGS                  //
// *********************************** //
// Specific style for quotes
.styled-quote {
  font-family: "Source Serif Pro", "Apple Garamond", "Baskerville",
    "Libre Baskerville", "Droid Serif", "Times New Roman", "Times", serif,
    "Noto Emoji", "Quivira";
  font-size: calc(var(--font-size) * 1.15);
  line-height: 1.3;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  padding-left: 15px;
  margin: 20px 0;
  color: #fff;
}

.tags-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 768px) {
    width: 50%;
  }

  li {
    margin: 0;
  }

  .tag-link {
    display: inline-block;
    padding: 5px 10px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--bg-tertiary);
    }
  }
}
